import { captureRemixErrorBoundaryError, withSentry, SentryMetaArgs } from "@sentry/remix";
import type { LinksFunction, MetaFunction, LoaderFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  LiveReload,
} from "@remix-run/react";
import acceptLanguage from 'accept-language-parser';
import { LocaleProvider } from "./hooks/useLocale";
import React from 'react';

import tailwindStylesheetUrl from "./tailwind.css?url";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindStylesheetUrl },
];

import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { ClerkApp } from "@clerk/remix";

export const meta = ({ data }: SentryMetaArgs<MetaFunction>) => {
  const metaFunctions = [
    { title: "Vixel Dashboard" },
    { name: "description", content: "Welcome to Vixel Payments!" },
  ];

  if (data) {
    metaFunctions.push(
      { name: "sentry-trace", content: data.sentryTrace },
      { name: "baggage", content: data.sentryBaggage },
    );
  }

  return metaFunctions;
};

type LoaderData = {
  locale: string;
}

// Export as the root route loader
export const loader: LoaderFunction = (args) => {
  return rootAuthLoader(args, ({ request }): LoaderData => {
    const acceptLanguageHeader = request.headers.get("Accept-Language");

    if (!acceptLanguageHeader) {
      return { locale: "en-us" };
    }

    const languages = acceptLanguage.parse(acceptLanguageHeader as string);

    // if languages is empty, return a default locale
    if (languages === null || languages.length === 0) {
      return { locale: "en-us" };
    }

    // If there is no region for this locale, just return the code
    if (!languages[0].region) return { locale: languages[0].code as string };

    return {
      locale: `${languages[0].code}-${languages[0].region.toLowerCase()}`,
    };
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full bg-gray-100">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {children}
        <ScrollRestoration />
        <Scripts />
        {/* <LiveReload /> */}
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <LocaleProvider locale={data.locale}>
      <Outlet />
    </LocaleProvider>
  );
}

export default ClerkApp(App);

// export function ErrorBoundary() {
//   const error = useRouteError();
//   captureRemixErrorBoundaryError(error);

//   return (
//     <html lang="en" className="h-full bg-gray-100">
//       <head>
//         <meta charSet="utf-8" />
//         <meta name="viewport" content="width=device-width, initial-scale=1" />
//         <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
//         <title>Oops!</title>
//         <Meta />
//         <Links />
//       </head>
//       <body className="h-full">
//         <h1>
//           {isRouteErrorResponse(error)
//             ? `${error.status} ${error.statusText}`
//             : error instanceof Error
//             ? error.message
//             : "Unknown Error"}
//         </h1>
//         <h2>Not found?</h2>
//         <Scripts />
//       </body>
//     </html>
//   );
// }
