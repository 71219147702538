import type { MetaFunction } from "@remix-run/node";
import { SentryMetaArgs } from "@sentry/remix";

import { SignedIn, SignIn, SignedOut, SignOutButton } from "@clerk/remix";

import Sidebar from "../components/Sidebar";
import DashboardCard from "../components/data/DashboardCard";
import KPICards from "../components/data/KPICards";
import { PageHeader } from "../components/layout/PageHeader";

export const meta = ({ data }: SentryMetaArgs<MetaFunction>) => {
  const metaFunctions = [
    { title: "Vixel Dashboard" },
    { name: "description", content: "Welcome to Vixel Payments!" },
  ];

  if (data) {
    metaFunctions.push(
      { name: "sentry-trace", content: data.sentryTrace },
      { name: "baggage", content: data.sentryBaggage },
    );
  }

  return metaFunctions;
};

export default function Index() {
  return (
    <div>
      <SignedOut>
        <div className="flex min-h-full flex-1 justify-center px-6 py-12 lg:px-8">
          <SignIn />
        </div>
      </SignedOut>

      <SignedIn>
        <Sidebar>
          <PageHeader title="Dashboard" />

          <div className="mt-8 mb-8">
            <KPICards />
          </div>
          <div className="mt-8 mb-8">
            <DashboardCard />
          </div>

          <div style={{ lineHeight: "1.8" }}>
            <ul>
              <li>
                <SignOutButton>
                  <button
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >Sign Out</button>
                </SignOutButton>
              </li>
            </ul>
          </div>
        </Sidebar>
      </SignedIn>
    </div>
  );
}
